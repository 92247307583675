.card {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    width: 95%;
    height: 85%;
    scroll-snap-align: start;
    margin: 7% auto;
    border-radius: 20px;
    background-color: #FFFFFF;
  }

  .desktopCard {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    width: 95%;
    height: 94%;
    scroll-snap-align: start;
    border-radius: 20px;
    background-color: #FFFFFF;
    margin-top: 3%;
    margin-bottom: 3%;
  }

.sidebar {
    padding-right: 2%;
    width: 50vw;
    height: 70%;
    margin-top: -20%;
  }

  .sidebarDesktop {
    padding-right: 2%;
    width: 100vw;
    height: 100%;
    padding-left: 2%;
    padding-top: 2%;
  }

  .video__playerDesktop {
    margin-top: 0%;
  }

.video__player {
  margin-top: -20%;
}

.sidebarLast {
  padding-right: 2%;
  width: 50vw;
  height: 70%;
  margin-top: -40%;
}

.video__playerLast {
  margin-top: -40%;
}


.play__icon {
  position: absolute;
  z-index: 1;
  right: 100px;
  font-size: 40;
  color: red
}