html {
  scroll-snap-type: y mandatory;
}

.quidvidViewer {
  background-color: #FFFFFF;
  display: grid;
  place-items: center;
  margin: 0 auto;
  position: fixed;
  width: 100vw;
}

.quidvidViewer__cards {
  position: relative;
  overflow: scroll;
  width: 100vw;
  max-width: 500px;
  height: 70vh;
  scroll-snap-type: y mandatory;
  background-color: #F8F8F8;
}

.quidvidViewer__cardsDesktop {
  position: relative;
  overflow: scroll;
  width: 95%;
  height: 70vh;
  scroll-snap-type: y mandatory;
  background-color: #F8F8F8;
  padding-left: 5%;
}

/* Hide scrollbar for Chrome, Safari and Opera */
.quidvidViewer__cards::-webkit-scrollbar {
  display: none;
}

/* Hide scrollbar for IE, Edge and Firefox */
.quidvidViewer__cards  {
  -ms-overflow-style: none; /* IE and Edge */
  scrollbar-width: none; /* Firefox */
}