.dodecagon1 {
  width: 15vw;
  height: 15vw;
  clip-path: polygon(13.4% 13.4%,
    36.6% 0, 63.4% 0,
    86.8% 13.4%,
    100% 36.6%,
    100% 63.4%,
    86.6% 86.6%,
    63.4% 100%,
    36.6% 100%,
    13.4% 86.6%,
    0 63.4%,
    0 36.6%
    );
}

.dodecagon2 {
  cursor: pointer;
  width: 15vw;
  height: 15vw;
  clip-path: polygon(13.4% 13.4%,
    36.6% 0, 63.4% 0,
    86.8% 13.4%,
    100% 36.6%,
    100% 63.4%,
    86.6% 86.6%,
    63.4% 100%,
    36.6% 100%,
    13.4% 86.6%,
    0 63.4%,
    0 36.6%
    );
}

.dodecagon3 {
  width: 30vw;
  height: 30vw;
  clip-path: polygon(13.4% 13.4%,
    36.6% 0, 63.4% 0,
    86.8% 13.4%,
    100% 36.6%,
    100% 63.4%,
    86.6% 86.6%,
    63.4% 100%,
    36.6% 100%,
    13.4% 86.6%,
    0 63.4%,
    0 36.6%
    );
}

.dodecagon4 {
  cursor: pointer;
  width: 30vw;
  height: 30vw;
  clip-path: polygon(13.4% 13.4%,
    36.6% 0, 63.4% 0,
    86.8% 13.4%,
    100% 36.6%,
    100% 63.4%,
    86.6% 86.6%,
    63.4% 100%,
    36.6% 100%,
    13.4% 86.6%,
    0 63.4%,
    0 36.6%
    );
}