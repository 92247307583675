.centre {
    display: flex;
    flex-direction: column;
    padding: 2%;
    font-family: avenir;
    color: #282929;
    justify-content: center;
    align-items: center;
    text-align: left;
}

.centre p {
    color: #A2A39A;
    width: 50%;
}

.centre ul {
    color: #A2A39A;
}
